// @flow

import React from "react";
import { graphql } from "gatsby";

import { Page } from "../../components";

/**
 * Query for the homepage
 */
export const query = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        page: contentfulPage(slug: { eq: $slug }) {
            ...PageContent
        }
    }
`;

const PageTemplate = ({ data: { page, site } }: *) => (
    <Page page={page} site={site} />
);

/**
 * Homepage
 */
export default PageTemplate;
